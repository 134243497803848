import type { ReactNode } from 'react'
import React, { useEffect, useState } from 'react'
// Auth Component - https://supabase.com/docs/guides/auth/auth-helpers/auth-ui#customization
// Auth UI Code - https://github.com/supabase/auth-ui/blob/main/packages/react/src/components/Auth/Auth.tsx
import type { ViewType } from '@supabase/auth-ui-shared'
import {
    Auth,
    SocialAuth,
} from '@supabase/auth-ui-react'

import type { GenericObject } from '@act/lib/src/types'
import {
    actAppearance,
    checkHasUser,
    getLoginRedirectUrl,
    getSupabaseClient,
} from '@act/lib/src/supabase'

import { getAnyWindowPath, getAnyWindowUrl, loginRedirectFromUrl } from '@act/lib/src/url'
import markSvgXml from '~dash/src/assets/logomark.svg?raw'

const hasUser = await checkHasUser()

const initialView = 'magic_link'

const localizationVariables: GenericObject = {
    // Localization Labels - https://supabase.com/docs/guides/auth/auth-helpers/auth-ui#custom-labels
    // Options - https://github.com/supabase/auth-ui/blob/main/packages/react/common/lib/Localization/en.json
    sign_up: {
        heading: 'Sign up',
        subheading: 'Create an account',
    },
    sign_in: {
        heading: 'Sign in',
        subheading: 'Welcome back! Enter your email to sign in.',

        email_label: 'Email',
        password_label: 'Password',
        email_input_placeholder: 'Enter your email',
        password_input_placeholder: '••••••••',
        button_label: 'Sign in',
        social_provider_text: 'Sign in with',
        link_text: 'Already have an account? Sign in',
    },
    magic_link: {
        heading: 'Sign in',
        subheading: 'Enter your email address to sign in',

        email_input_label: 'Email',
        email_input_placeholder: 'Enter your email',
        button_label: 'Sign in',
        link_text: 'Sent a magic link! Check your email. ',
    },
}

function AuthUI () {
    const [ authView, setAuthView ] = useState<ViewType>( initialView )

    const postLoginUrl = React.useMemo( (): string => {
        const anyWindowUrl = getAnyWindowUrl()
        const anyWindowPath = getAnyWindowPath()

        // If we're at the root of the site then use loginRedirectFromUrl
        if (
            !anyWindowUrl
            || !anyWindowPath
            || [ '/', '/login' ].includes( anyWindowPath )
        ) {
            return loginRedirectFromUrl.href
        }

        // Otherwise use the current window path
        return anyWindowUrl
    }, [] )

    useEffect( () => {
        // Add a listener to the Auth UI to listen for the 'authenticated' event'
        // so that we can manually trigger a load to the postLoginUrl
        // theoretically Auth.props.redirectTo should do this, but it doesn't
        // in some cases
        //
        // https://supabase.com/docs/reference/javascript/v1/auth-onauthstatechange
        const { data: { subscription } } = getSupabaseClient().auth.onAuthStateChange( ( event ) => {
            if ( event === 'SIGNED_IN' ) {
                // console.log( 'Auth UI - SIGNED_IN', { session } )
                // Since we're signed in now we have a user and session data
                // and we'll want to fetch the loginRedirectUrl one more time
                // so that we don't redirect a non-onboarded user to the dashboard
                getLoginRedirectUrl()
                    .then( ( url ) => {
                        if ( !url ) {
                            // Reload the window if we don't have a redirect URL
                            window.location.reload()
                            return
                        }

                        // console.log( 'Redirect from AuthOnly', url.href )
                        window.location.href = url
                    } )
            }
        } )

        return () => {
            subscription.unsubscribe()
        }
    }, [] )

    return (
        <div className='auth-container flex flex-col justify-center items-center min-h-screen text-gray-500 bg-slate-50'>

            <div
                className='auth-wrapper max-w-md w-full flex flex-col justify-center items-center bg-white gap-6 shadow-frame'
            >

                <div className='auth-body w-full flex flex-col justify-center items-center gap-2 px-12 pt-12'>
                    <div
                        className='mark-wrapper w-16 h-16 flex [&>svg]:h-full'
                        dangerouslySetInnerHTML={ { __html: markSvgXml } }
                    />

                    <div className='flex flex-col items-center gap-4'>
                        <h1 className='font-bold text-3xl text-gray-900'>{ localizationVariables[ authView ].heading }</h1>
                        <div className='subheading text-sm text-gray-500'>{ localizationVariables[ authView ].subheading }</div>
                    </div>

                    <div className='w-full'>

                        <Auth
                            // Set Redirect URLs under Project > Authentication > URL Configuration
                            redirectTo={ postLoginUrl }
                            view={ authView }
                            showLinks={ false }
                            supabaseClient={ getSupabaseClient() }
                            theme='default'
                            appearance={ actAppearance( {
                                className: {
                                    container: 'w-full',
                                },
                            } ) }
                            localization={ {
                                variables: localizationVariables,
                            } }
                            providers={ [] }
                            socialLayout='vertical'
                        />

                        <SocialAuth
                            redirectTo={ postLoginUrl }
                            appearance={ actAppearance( {
                                className: {
                                    button: '',
                                },
                            } ) }
                            localization={ {
                                variables: {
                                    sign_in: {
                                        social_provider_text: 'Sign in with LinkedIn',
                                    },
                                    sign_up: {
                                        social_provider_text: 'Sign up with LinkedIn',
                                    },
                                },
                            } }
                            supabaseClient={ getSupabaseClient() }
                            providers={ [ 'linkedin' ] }
                            socialLayout='vertical'
                        />

                    </div>

                    <div className='auth-links flex gap-2'>
                        <div>
                            { 'Don\'t have an account?' }
                        </div>

                        <button
                            onClick={ () => setAuthView( 'sign_up' ) }
                            className='text-brand-primary hover:underline'
                        >
                        Sign up
                        </button>
                    </div>

                    <button
                        onClick={ () => setAuthView( 'sign_in' ) }
                    >
                    Use Password
                    </button>
                </div>

                <div className='auth-footer w-full flex justify-center items-center p-8'>
                    <div className='copyright w-full text-sm'>
                    © ACT House { new Date().getFullYear() }
                    </div>
                </div>

            </div>
        </div>
    )
}

export interface AuthOnlyProps {
    // [key: string]: any
    children: ReactNode
}

/**
 * Shows content only visible to authenticated users
 * or shows the Auth UI if the user is not authenticated
 *
 * @param props
 * @returns
 */
export function AuthOnly ( props: AuthOnlyProps ) {
    if ( hasUser ) {
        return props.children
    }

    return <AuthUI />
}
